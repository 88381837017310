import TableManager from '../TableManager';

const Home = () => {
    return (
        <div className="container-fluid">
            <TableManager />
        </div>
    );
};

export default Home;
